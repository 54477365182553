import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus, faTasks, faCheck, faTimes, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import FormModal from './FormModal';
import '../styles/DataList.css';
import { useAuth } from '../hooks/useAuth';
import { CSVLink } from "react-csv";

const DataList = ({ apiConfig, columns }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { rolePermissions, user, token } = useAuth();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, data]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.fetchEndpoint}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },           
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getValueBySelector = (item, selector) => {
    if (!selector) return null;
    const keys = selector.split('.');
    return keys.reduce((value, key) => (value ? value[key] : null), item);
  };

  const applyFilters = () => {
    const processedFilters = {};
    Object.keys(filters).forEach((key) => {
      const column = columns.find((col) => col.name === key);
      const isInteger = column?.type === 'integer';
      processedFilters[key] = isInteger ? parseInt(filters[key], 10) : filters[key].toLowerCase();
    });

    const filteredData = data.filter((item) =>
      Object.keys(processedFilters).every((key) => {
        const filterValue = processedFilters[key];
        const column = columns.find((col) => col.name === key);
        const selector = column?.filterSelector || key;
        const itemValue = getValueBySelector(item, selector);

        if (column?.type === 'integer') {
          return !filterValue || itemValue === filterValue;
        }

        return !filterValue || String(itemValue || '').toLowerCase().includes(filterValue);
      })
    );

    setFilteredData(filteredData);
  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters({});
  };

  const renderFilters = () => {
    return (
      <div className="filter-container">
        {columns
          .filter((column) => column.filterable)
          .map((column) => (
            <div key={column.name} className="filter-item">
              <input
                type="text"
                name={column.name}
                placeholder={`Filter by ${column.name}`}
                value={filters[column.name] || ''}
                onChange={handleFilterChange}
              />
            </div>
          ))}
        <button className="clear-filters" onClick={clearFilters}>
          Clear Filters
        </button>
        <CSVLink
          data={filteredData}
          filename={`${apiConfig.title.replace(/\s+/g, '_').toLowerCase()}_export.csv`}
          className="export-button"
        >
          <FontAwesomeIcon icon={faFileExcel} /> Export
        </CSVLink>
      </div>
    );
  };

  const handleDelete = async (row) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      try {
        const id = getIdentifierValue(row);

        await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.deleteEndpoint}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            }
        );
        fetchData();
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('Failed to delete record.');
      }
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleBulkAction = async (action) => {
    if (selectedRows.length === 0) {
      alert('Please select at least one record for bulk action.');
      return;
    }

    if (window.confirm(`Are you sure you want to execute '${action.name}' for the selected records?`)) {
      try {
        const selectedIds = selectedRows;
        const payload = {
          ids: selectedIds,
          updatedBy: user.portal_id,
        };

        await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}${action.endpoint}`,
          payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
  
            }
        );
        alert(`Bulk action '${action.name}' executed successfully!`);
      } catch (error) {
        console.error(`Error executing bulk action '${action.name}':`, error);
        alert(`Failed to execute '${action.name}': ${error.response?.data?.error}`);
      } finally {
        fetchData();
        setSelectedRows([]);
      }
    }
  };

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows.map((row) => getIdentifierValue(row)));
  };

  const renderColumnCell = (column, row) => {
    if (column.type === 'boolean') {
      return column.selector(row) ? (
        <FontAwesomeIcon icon={faCheck} className="boolean-true" />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="boolean-false" />
      );
    }
    return column.selector(row);
  };

  const getIdentifierValue = (row) => {
    const identifierCol = columns.find((col) => col.identifier);
    return identifierCol ? identifierCol.selector(row) : null;
  };

  return (
    <div className="data-list">
      <div className="header">
        <h2>{apiConfig.title}</h2>
        <div className="actions">
          {rolePermissions?.[apiConfig.path]?.Add === 'true' && (
            <button className="add-button" onClick={() => setIsModalVisible(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          )}
          {selectedRows.length > 0 && rolePermissions?.[apiConfig.path]?.Edit === 'true' && (
            <div className="bulk-actions">
              <FontAwesomeIcon icon={faTasks} />
              <select
                defaultValue=""
                onChange={(e) =>
                  handleBulkAction(apiConfig.bulkActions.find((action) => action.name === e.target.value))
                }
              >
                <option value="">Select Action</option>
                {apiConfig.bulkActions && apiConfig.bulkActions.length > 0
                  ? apiConfig.bulkActions.map((action) => (
                      <option key={action.name} value={action.name}>
                        {action.name}
                      </option>
                    ))
                  : <option value="" disabled>No actions available</option>}
              </select>
            </div>
          )}
        </div>
      </div>

      <DataTable
        columns={[
          ...columns.map((col) => ({
            ...col,
            cell: (row) => renderColumnCell(col, row),
          })),
          {
            name: 'Actions',
            cell: (row) => (
              <>
                {rolePermissions?.[apiConfig.path]?.Edit === 'true' && (
                  <button onClick={() => handleEdit(row)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                )}
                {rolePermissions?.[apiConfig.path]?.Delete === 'true' && (
                  <button onClick={() => handleDelete(row)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </>
            ),
          },
        ]}
        data={filteredData}
        progressPending={isLoading}
        selectableRows
        onSelectedRowsChange={handleSelectedRowsChange}
        pagination
        highlightOnHover
        defaultSortFieldId={apiConfig.defaultSortFieldId} // New default sort field
        defaultSortAsc={apiConfig.defaultSortAsc ?? true} // New sort order
        subHeader
        subHeaderComponent={renderFilters()}
      />

      {isModalVisible && (
        <FormModal
          initialData={selectedRecord}
          onClose={() => {
            setIsModalVisible(false);
            setSelectedRecord(null);
            fetchData();
          }}
          apiConfig={apiConfig}
        />
      )}
    </div>
  );
};

export default DataList;
