import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../hooks/useAuth'; // Assuming you have a useAuth hook

const FormModal = ({ initialData, onClose, apiConfig }) => {
  const { user,token } = useAuth();
  const userRole = user?.role;

  const formatDateForInput = (date) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      return parsedDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
    }
    return date;
  };

  const initializeFormData = () => {
    const data = initialData || {};
    apiConfig.fields.forEach((field) => {
      // Apply default value only if the field is undefined
      if (data[field.name] === undefined && field.defaultValue !== undefined) {
        data[field.name] = field.defaultValue;
      }

      // Format date fields for input if they already have a value
      if (field.type === 'date' && data[field.name]) {
        data[field.name] = formatDateForInput(data[field.name]);
      }
    });
    return data;
  };

  const [formData, setFormData] = useState(initializeFormData());
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [loadingOptions, setLoadingOptions] = useState(true);

  // Determine the initial active tab
  const accessibleTabs = (apiConfig.tabs || []).filter((tabConfig) => {
    if (tabConfig.roles === 'all') {
      return true; // Bypass security check for "all"
    }
    const allowedRoles = tabConfig.roles?.split(',').map((role) => role.trim()) || [];
    return allowedRoles.includes(userRole);
  });

  const defaultTab =
    accessibleTabs.find((tabConfig) => tabConfig.default) || accessibleTabs[0] || { name: 'Main' };

  const [activeTab, setActiveTab] = useState(defaultTab.name);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  useEffect(() => {
    console.log("Form Data:",initialData);
    if (apiConfig.dropdownFields || apiConfig.staticDropdownFields) {
      fetchDropdownOptions();
    }
  }, [apiConfig.dropdownFields, apiConfig.staticDropdownFields]);

  const fetchDropdownOptions = async () => {
    setLoadingOptions(true);
    const options = {};

    if (apiConfig.staticDropdownFields) {
      for (const field of apiConfig.staticDropdownFields) {
        options[field.name] = field.options.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
    }

    if (apiConfig.dropdownFields) {
      for (const field of apiConfig.dropdownFields) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${field.endpoint}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
          options[field.name] = response.data.map((item) => ({
            value: item[field.valueField],
            label: item[field.labelField],
          }));
        } catch (error) {
          console.error(`Error fetching options for ${field.name}:`, error);
        }
      }
    }

    setDropdownOptions(options);
    setLoadingOptions(false);
  };

  const getIdentifierValue = () => {
    const identifierField = apiConfig.fields.find((field) => field.identifier);
    return identifierField ? formData[identifierField.name] : null;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: fieldValue });
  };

  const handleDropdownChange = (selectedOption, action) => {
    setFormData({ ...formData, [action.name]: selectedOption?.value || '' });
  };

  const isFormValid = () => {
    return apiConfig.fields
      .filter((field) => field.required)
      .every((field) => !!formData[field.name]);
  };

  const simulateDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const identifierValue = getIdentifierValue();
    const payload = includeUpdatedBy(formData, user.portal_id)

    try {
       // await simulateDelay(2000);
      if (identifierValue) {
        await axios.put(
          `${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.updateEndpoint}/${identifierValue}`,
          payload,
          {
            headers: {
                Authorization: `Bearer ${token}`,
            },

        }
        );
        alert('Record updated successfully!');
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.createEndpoint}`,
          payload,
          {  
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Record created successfully!');
      }
      onClose();
    } catch (error) {
      console.error('Error saving record:', error);
      alert('Failed to save record.');
    } finally {
        setIsSubmitting(false); // Re-enable the button
      }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '40px',
      minHeight: '40px',
      backgroundColor: state.isDisabled ? '#e9ecef' : provided.backgroundColor,
      padding: '0px',
      marginBottom: '0px',
      marginRight: '0px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 8px',
    }),
    container: (provided) => ({
        ...provided,
        padding: '0px 0px',
        margin: '0px 0px',
    })
  };

  const ensureOptionIncluded = (field, value) => {
    const options = dropdownOptions[field.name] || [];
    const exists = options.some((option) => option.value === value);
    if (!exists && value) {
      const newOption = { value, label: value };
      options.push(newOption);
      setDropdownOptions({ ...dropdownOptions, [field.name]: options });
    }
  };

  const groupedFields = apiConfig.fields.reduce((groups, field) => {
    const tab = field.tab || 'Main';
    if (!groups[tab]) groups[tab] = [];
    groups[tab].push(field);
    return groups;
  }, {});

  const includeUpdatedBy = (data, userId) => ({
    ...data,
    updatedBy: userId,
});

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>
          {getIdentifierValue() ? `Edit ${apiConfig.title}` : `Add ${apiConfig.title}`}
        </h2>

        <div className="tabs">
          {accessibleTabs.map((tabConfig) => (
            <button
              key={tabConfig.name}
              className={`tab-button ${activeTab === tabConfig.name ? 'active' : ''}`}
              onClick={() => setActiveTab(tabConfig.name)}
            >
              {tabConfig.name}
            </button>
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          {groupedFields[activeTab]?.map((field) => (
            <div className="form-group-horizontal" key={field.name}>
              <label
                style={{ color: field.required ? 'red' : 'inherit' }}
              >
                {field.label}
                {field.required && '*'}
              </label>
              {field.type === 'dropdown' ? (
                loadingOptions ? (
                  <p>Loading options...</p>
                ) : (
                  <Select
                    name={field.name}
                    options={dropdownOptions[field.name] || []}
                    value={
                      dropdownOptions[field.name]?.find(
                        (option) => option.value === formData[field.name]
                      ) || null
                    }
                    onChange={handleDropdownChange}
                    isClearable
                    placeholder={`Select ${field.label}`}
                    styles={customStyles}
                    isDisabled={field.disabled}
                  />
                )
              ) : field.type === 'checkbox' ? (
                <input
                  type="checkbox"
                  name={field.name}
                  checked={!!formData[field.name]}
                  onChange={handleInputChange}
                  disabled={field.disabled}
                />
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleInputChange}
                  disabled={field.disabled}
                />
              )}
            </div>
          ))}
          <div className="form-actions">
            <button type="submit" disabled={!isFormValid() || loadingOptions || isSubmitting}>
              Save
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormModal;
