import React, { useState } from 'react';
import axios from 'axios';

const TestDocumentAI = () => {
  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState('Lab'); // Default document type
  const [results, setResults] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please upload a PDF file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/lab-results/process-pdf?DocumentType=${documentType}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      setResults(response.data);
    } catch (error) {
      console.error('Error processing the PDF:', error);
      setResults('Error processing the document');
      alert('Failed to process the PDF.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Test Document AI</h1>
      <div>
        <label htmlFor="document-type">Document Type:</label>
        <select id="document-type" value={documentType} onChange={handleDocumentTypeChange}>
          <option value="Lab">Lab</option>
          <option value="CBC">CBC</option>
          <option value="Urine">Urine</option>
        </select>
      </div>
      <div>
        <input type="file" onChange={handleFileChange} />
      </div>
      <div>
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? 'Processing...' : 'Process PDF'}
        </button>
      </div>

      {Object.keys(results).length > 0 && (
        <div>
          <h2>Extracted Data</h2>
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(results).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TestDocumentAI;
