import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ requiredRoles, requiredPermissions }) => {
  const { user, rolePermissions, loading } = useAuth();
  const location = useLocation();

  //console.log('PrivateRoute - location:', location.pathname);
  //console.log('PrivateRoute - user:', user);
  //console.log('PrivateRoute - rolePermissions:', rolePermissions);

  if(loading) {
    return <p>Loading</p>;
  }
  
  // Check if user is logged in
  if (!user) {
    //console.log('User not logged in, redirecting to login');
    return <Navigate to="/login" />;
  }

  // Check if the user has the required roles
  if (requiredRoles && !requiredRoles.includes(user.role)) {
    //console.log('User does not have the required roles, redirecting to login');
    return <Navigate to="/login" />;
  }

  // Check if the user has the required permissions
  if (requiredPermissions) {
    const permissionCheck = requiredPermissions.every(permission => rolePermissions[location.pathname]?.[permission] === 'true');
    if (!permissionCheck) {
      console.log('User does not have the required permissions, redirecting to login');
      return <Navigate to="/login" />;
    }
  }

  return <Outlet />;
};

export default PrivateRoute;
