import React from 'react';
import DataList from './DataList';
import { getFormattedDate } from '../assets/Utils';

const EmployeeList = () => {
    
    
    const apiConfig = {
      title: 'Employees',
      path: '/employee-list',
      fetchEndpoint: '/hospital-portal/api/employees/',
      createEndpoint: '/hospital-portal/api/employees',
      updateEndpoint: '/hospital-portal/api/employees',
      deleteEndpoint: '/hospital-portal/api/employees',
      defaultSortFieldId: 'hireDate',
      defaultSortAsc:false,
      dropdownFields: [
        {
          name: 'manager',
          endpoint: '/hospital-portal/api/employees',
          valueField: 'employee_id',
          labelField: 'employee_name',
        },
        {
            name: 'updatedBy',
            endpoint: '/hospital-portal/api/users',
            valueField: 'portal_id',
            labelField: 'name',
          },
      ],
      staticDropdownFields: [
        {
            name: 'role',
            options: [
                { id: 'Management', name: 'Management' },{ id: 'Pharmacist', name: 'Pharmacist' },
                { id: 'Driver', name: 'Driver' },{ id: 'Doctor', name: 'Doctor' },
                { id: 'Nurse', name: 'Nurse' },{ id: 'Lab Staff', name: 'Lab Staff' },
                { id: 'Supervisor', name: 'Supervisor' },{ id: 'Admin', name: 'Admin' },
                { id: 'Porter', name: 'Porter' },{ id: 'Night Cashier', name: 'Night Cashier' },
                { id: 'Lab Manager', name: 'Lab Manager' },{ id: 'Accountant', name: 'Accountant' },
                { id: 'Reception', name: 'Reception' },{ id: 'Pharmacy', name: 'Pharmacy' },
                { id: 'X-ray Staff', name: 'Xray Staff' },{ id: 'Logistics Staff', name: 'Logistics Staff' },
                { id: 'Cleaner', name: 'Cleaner' },{ id: 'Guardian', name: 'Guardian' },
                { id: 'HR', name: 'HR' },{ id: 'Electrician', name: 'Electrician' },
                { id: 'Technician', name: 'Technician' },{ id: 'CHO', name: 'CHO' },
                { id: 'Theatre Staff', name: 'Theatre Staff' },{ id: 'Guardian', name: 'Guardian' },
                { id: 'Cleaner', name: 'Cleaner' },{ id: 'Maintenance', name: 'Maintenance' },
                { id: 'Bikeman', name: 'Bikeman' },{ id: 'Other', name: 'Other' },
                
            ],
        },
        {
            name: 'branch',
            options:[
                { id: 'Poultney', name: 'Poultney'},
                { id: 'Kissy', name: 'Kissy'},

            ],
        },
        {
            name: 'salary_period',
            options:[
                { id: 'Monthly', name: 'Monthly'},
                { id: 'Monthly', name: 'Monthly'},

            ],
        },
        {
            name: 'department',
            options: [
                { id: 'Management', name: 'Management' },{ id: 'Pharmacy', name: 'Pharmacy' },
                { id: 'Driver', name: 'Transportation' },{ id: 'Medical', name: 'Medical' },{ id: 'Lab', name: 'Lab' },
                { id: 'Staff', name: 'Staff' },{ id: 'Cashier', name: 'Cashier' },{ id: 'Finance', name: 'Finance' },
                { id: 'Reception', name: 'Reception' },{ id: 'X-ray Staff', name: 'Xray Staff' },{ id: 'Logistics', name: 'Logistics' },
                { id: 'HR', name: 'HR' },{ id: 'Maintenance', name: 'Maintenance' },{ id: 'Other', name: 'Other' },
            ],
        },
        ],
      fields: [
        { name: 'employee_id', label:'Employee ID', type:'text', identifier:true, disabled:true,tab:'Employee' },
        { name: 'employee_name', label: 'Name', type: 'text',required:true,tab:'Employee' },
        { name: 'role', label: 'Role', type: 'dropdown', required:true,tab:'Employee' },
        { name: 'department', label: 'Department', type: 'dropdown', required:true,tab:'Employee' },
        { name: 'branch', label: 'Branch', type: 'dropdown', required:true,tab:'Employee' },
        { name: 'hire_date', label: 'Hire Date', type: 'date', required:true, defaultValue: getFormattedDate(),tab:'Employee' },
        { name: 'manager', label: 'Manager', type: 'dropdown',tab:'Employee' },
        { name: 'status', label: 'Status', type: 'checkbox',tab:'Employee' },
        { name: 'salary', label: 'Salary', type: 'number', tab:'Pay' },
        { name: 'annual_benefit_amount', label: 'Annual Benefit', type: 'number', tab:'Pay' },
        { name: 'salary_period', label: 'Salary Period', type: 'dropdown',tab:'Pay', defaultValue:'Monthly' },
        {name: 'createdAt', label: 'Date Created', type: 'text',tab:'Admin', disabled:true},
        {name: 'updatedAt', label: 'Date Updated', type: 'text',tab:'Admin', disabled:true},
        {name: 'updatedBy', label: 'Updated By', type: 'dropdown',tab:'Admin', disabled:true},

      ],
      tabs: [
        {name: 'Employee', roles: 'all',default:true},
        {name: 'Pay', roles: 'Admin,Accountant'},
        {name: 'Admin', roles: 'Admin'}
      ]
    };
  
    const columns = [
      { name: 'ID', selector: (row) => row.employee_id, sortable: true,identifier:true },  
      { name: 'Employee Name', selector: (row) => row.employee_name, sortable: true,filterable: true,filterSelector: 'employee_name' },
      { id: 'hireDate',name: 'Hire Date', selector: (row) => row.hire_date, sortable: true },
      { name: 'Role', selector: (row) => row.role, sortable: true,filterable: true,filterSelector:'role' },
      { name: 'Branch', selector: (row) => row.branch, sortable: true, filterable: true, filterSelector: 'branch' },
      { name: 'Status', selector: (row) =>row.status, sortable: true, type: 'boolean'},
    ];
  
    return <DataList apiConfig={apiConfig} columns={columns} />;
  };
  
  export default EmployeeList;