import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';
import { setupAxiosInterceptors } from '../assets/Utils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [rolePermissions, setRolePermissions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setupAxiosInterceptors();
  },[]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Retrieve ID token
          const idToken = await firebaseUser.getIdToken(true);
          setToken(idToken);


        // Attach the token to Axios requests
        //axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
 
          // Fetch user details
          const userResponse = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_USERS}/firebase/${firebaseUser.uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          setUser(userResponse.data);

          // Fetch role permissions
          const roleResponse = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}/${userResponse.data.role}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          setRolePermissions(roleResponse.data.permissions);
        } catch (error) {
          console.error('Error fetching user or permissions:', error);
        }
      } else {
        setUser(null);
        setToken(null);
        setRolePermissions({});
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const firebaseUser = userCredential.user;

    // Retrieve ID token
    const idToken = await firebaseUser.getIdToken(true);
    setToken(idToken);

    // Fetch user details
    const userResponse = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_USERS}/firebase/${firebaseUser.uid}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    setUser(userResponse.data);

    // Fetch role permissions
    const roleResponse = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}/${userResponse.data.role}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    setRolePermissions(roleResponse.data.permissions);
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    setToken(null);
    setRolePermissions({});
  };

  return (
    <AuthContext.Provider value={{ user, token, rolePermissions, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
